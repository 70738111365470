import SEO from '@components/SEO';
import IconArrow2 from '@icons/IconArrow2';
import IconArrow3 from '@icons/IconArrow3';
import Footer from '@views/default-layout/footer/Footer';
import { Col, Row } from 'antd';
import React, { memo } from 'react';

const Privacy = memo(() => {
   return (
      <>
         <SEO title={'Privacy Policy'} />
         <Row gutter={30} justify="center">
            <Col xl={16} lg={24} md={24} sm={24} xs={24}>
               <div className="hard-content-page">
                  <div className="title">
                     <span>Privacy Policy</span>
                     <span className="s-arrow animate__animated animate__rotateInDownLeft animate__slow">
                        <IconArrow3 />
                     </span>
                  </div>
                  <div className="phase">
                     This Privacy Policy describes how vgzcustom.com (the “Site” or “we”)
                     collects, uses, and discloses your Personal Information when you
                     visit or make a purchase from the Site.
                  </div>
                  <div className="phase">
                     <label>Collecting persional information</label>
                     <p>
                        When you visit the Site, we collect certain information about your
                        device, your interaction with the Site, and information necessary
                        to process your purchases. We may also collect additional
                        information if you contact us for customer support. In this
                        Privacy Policy, we refer to any information that can uniquely
                        identify an individual (including the information below) as
                        “Personal Information”. See the list below for more information
                        about what Personal Information we collect and why.
                     </p>
                     <ul>
                        <li>
                           <span className="bold">
                              Examples of Personal Information collected:
                           </span>{' '}
                           version of web browser, IP address, time zone, cookie
                           information, what sites or products you view, search terms, and
                           how you interact with the Site.
                        </li>
                        <li>
                           <span className="bold">Purpose of collection:</span> to load
                           the Site accurately for you, and to perform analytics on Site
                           usage to optimize our Site.
                        </li>
                        <li>
                           <span className="bold">Source of collection:</span> Collected
                           automatically when you access our Site using cookies, log
                           files, web beacons, tags, or pixels.
                        </li>
                     </ul>
                  </div>
                  <div className="phase">
                     <label>Sharing personal information</label>
                     <p>
                        We share your Personal Information with service providers to help
                        us provide our services and fulfill our contracts with you, as
                        described above. For example:
                     </p>
                     <ul>
                        <li>
                           We may share your Personal Information to comply with
                           applicable laws and regulations, to respond to a subpoena,
                           search warrant or other lawful request for information we
                           receive, or to otherwise protect our rights.
                        </li>
                     </ul>
                  </div>
                  <div className="phase">
                     <label>Cookies</label>
                     <p>
                        A cookie is a small amount of information that’s downloaded to
                        your computer or device when you visit our Site. We use a number
                        of different cookies, including functional, performance,
                        advertising, and social media or content cookies. Cookies make
                        your browsing experience better by allowing the website to
                        remember your actions and preferences (such as login and region
                        selection). This means you don’t have to re-enter this information
                        each time you return to the site or browse from one page to
                        another. Cookies also provide information on how people use the
                        website, for instance whether it’s their first time visiting or if
                        they are a frequent visitor.
                        <br />
                        <br />
                        We use the following cookies to optimize your experience on our
                        Site and to provide our services.
                     </p>
                  </div>
               </div>
            </Col>
         </Row>
         <Footer />
      </>
   );
});

export default Privacy;
